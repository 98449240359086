import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from '@app/services/store/store.service';
import { ApiService } from '@app/services/api/api.service';
import { IUserToken } from '@app/models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthLazyGuard {
  private token: IUserToken;

  constructor(
    private router: Router,
    private store: StoreService,
    private api: ApiService
  ) {
    this.store.state$.subscribe(async state => {
      this.token = state.token;
    });
  }

  public async canLoad() {
    // For more advanced checks, we can set 'data' to,
    // the app-routing.module and get it with next.data.role

    if (!!this.token) {
       this.api.retrieveLoggedUserOb(this.token).subscribe(user => {
          return true;
        },
        error => {
          this.router.navigateByUrl('auth/login');
          return false;
        });
    } else {
      this.router.navigateByUrl('auth/login');
      return false;
    }
  }
}
