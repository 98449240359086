<p-blockUI [blocked]="loading">
</p-blockUI>

<div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
  <div class="col-12 mt-5 xl:mt-0 text-center">
    <!--      <img src="assets/demo/images/notfound/logo-blue.svg" alt="Sakai logo" class="mb-5" style="width:81px; height:60px;">-->
    <!-- <img src="assets/images/logo-callmetrics.svg" alt="eCMS logo" class="mb-5" style="height:60px;"> -->
  </div>

  <div class="col-12 lg:col-8 md:col-10">
    <div class="card">
      <img src="assets/images/cms-Logo.png" alt="eCMS logo" class=" cardlogo">
      <p-steps [model]="stepItems" [(activeIndex)]="stepIndex" (activeIndexChange)="next()"
        [readonly]="true"></p-steps>
      <div class="grid">
        <div class="col-12 md:col-6 flex left-section">
          <div class="p-fluid">
            <div class="grid">
              <div class="col-12 " *ngIf="this.stepIndex == 0">
                <h4>First things first</h4>
                <label class="block text-md mb-5">Let'us know your contact informations.</label>
                <form [formGroup]="emailForm">
                  <span class="p-float-label">
                    <input type="email" class="w-full" name="username" id="username" pInputText
                      class="{{submitted && getEmailForm.username.errors ? 'ng-invalid ng-dirty' : ''}}"
                      aria-describedby="usernameHelp" autocomplete="off" formControlName="username"
                      style="padding:1rem;">
                    <label for="username" class="">Username</label>
                  </span>
                  <div *ngIf="submitted && getEmailForm.username.errors">
                    <div *ngIf="getEmailForm.username.errors.required" class="p-error">Username is required</div>
                    <div *ngIf="this.emailForm.get('username')?.hasError('pattern')" class="p-error">Username is not Valid</div>
                  </div>

                  <span class="p-float-label mt-3">
                    <input type="email" class="w-full" name="email" id="email" pInputText
                      class="{{submitted && getEmailForm.email.errors ? 'ng-invalid ng-dirty' : ''}}"
                      aria-describedby="emailHelp" autocomplete="off" formControlName="email" style="padding:1rem;">
                    <label for="email" class="">Email address</label>
                  </span>
                  <!--                <label for="email">Email address</label>-->
                  <!--                <input type="email" class="" name="email" id="email" placeholder="Enter email" autocomplete="off" formControlName="email" />-->
                  <div *ngIf="submitted && getEmailForm.email.errors">
                    <div *ngIf="getEmailForm.email.errors.required" class="p-error">Email is required</div>
                    <div *ngIf="this.emailForm.get('email')?.hasError('pattern')" class="p-error">Email must be a valid email address
                    </div>
                  </div>
                  <br />
                  <h6 class=" font-12">Let's get started by verifying your email address.</h6>
                  <h6 class=" font-12">You will receive an email shortly with a code required for the next step.</h6>
                </form>
              </div>

              <div id="check-email" class="col-12 " *ngIf="this.stepIndex == 1">
                <h4>Check your inbox</h4>
                <div class="">
                  <form [formGroup]="codeForm">
                    <div class="mb-2 "><label for="code">We just shared a code with you - please enter it below</label>
                    </div>
                    <input type="text" class="w-full" id="code" placeholder="Enter conformation code" pInputText
                      class="{{ submitted && getCodeForm.code.errors ? 'ng-invalid ng-dirty' : ''}}" autocomplete="off"
                      formControlName="code" />
                    <div *ngIf="submitted && getCodeForm.code.errors">
                      <div *ngIf="getCodeForm.code.errors.required" class="p-error">Verification code is required</div>
                    </div>
                    <br />
                    <h6 class="font-12">Didn't get the code?</h6>
                    <p><a (click)="resendCode()">Click here</a><span>&nbsp;&nbsp;to resend</span></p>
                  </form>
                </div>
              </div>

              <div id="profile" class="col-12 " *ngIf="this.stepIndex == 2">
                <form [formGroup]="profileForm">
                  <h4>Tell us about yourself</h4>
                  <div class="grid">
                    <div class="col-12 grid p-0 m-0">
                      <div class="col-6">
                        <span class="p-float-label">
                          <input type="text" class="" id="firstName" pInputText autocomplete="off"
                            formControlName="firstName"
                            class="{{submitted && getProfileForm.firstName.errors ? 'ng-invalid ng-dirty' : ''}}" />
                          <label for="firstName">First Name</label>
                        </span>
                        <div *ngIf="submitted && getProfileForm.firstName.errors">
                          <div *ngIf="getProfileForm.firstName.errors.required" class="p-error">First Name is required
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <span class="p-float-label">
                          <input type="text" class="w-full" id="lastName" pInputText autocomplete="off"
                            formControlName="lastName"
                            class="{{submitted && getProfileForm.lastName.errors ? 'ng-invalid ng-dirty' : ''}}" />
                          <label for="lastName">Last Name</label>
                        </span>
                        <div *ngIf="submitted && getProfileForm.lastName.errors">
                          <div *ngIf="getProfileForm.lastName.errors.required" class="p-error">Last Name is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 ">
                      <span class="p-float-label input-wrapper">
                        <input type="password" id="password" formControlName="password" class="w-full" pInputText
                          #password autocomplete="off"
                          class="{{submitted && getProfileForm.password.errors ? 'ng-invalid ng-dirty' : ''}}" />
                        <i class="pi {{ password.type === 'password' ? 'pi-eye' : 'pi-eye-slash' }} input-icon"
                          (click)="togglePasswords(password)"></i>
                        <label for="password">Password</label>
                      </span>
                      <div *ngIf="submitted && getProfileForm.password.errors">
                        <div *ngIf="getProfileForm.password.errors.required" class="p-error">Password is required</div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <span class="p-float-label input-wrapper">
                        <input type="password" id="passwordConfirm" formControlName="confirm_password" class="w-full"
                          pInputText #confirmPassword autocomplete="off"
                          class="{{submitted && getProfileForm.confirm_password.errors ? 'ng-invalid ng-dirty' : ''}}" />
                        <i class="pi {{ confirmPassword.type === 'password' ? 'pi-eye' : 'pi-eye-slash' }} input-icon"
                          (click)="togglePasswords(confirmPassword)"></i>
                        <label for="passwordConfirm">Password Confirmation</label>
                      </span>
                      <div *ngIf="submitted && getProfileForm.confirm_password.errors">
                        <div *ngIf="getProfileForm.confirm_password.errors.required" class="p-error">Password Confirm is
                          required</div>
                        <div *ngIf="getProfileForm.confirm_password.errors.confirmedValidator" class="p-error">Password
                          and Confirm Password must be match.</div>
                      </div>
                    </div>
                  </div>

                  <h4 class="mt-4">Business Information</h4>
                  <div class="grid">
                    <div class="col-12 ">
                      <span class="p-float-label">
                        <input type="text" id="businessName" formControlName="business_name"
                          class="{{submitted && getProfileForm.business_name.errors ? 'ng-invalid ng-dirty' : ''}}"
                          class="w-full" pInputText autocomplete="off" />
                        <label for="businessName">Your Business Name</label>
                      </span>
                      <div *ngIf="submitted && getProfileForm.business_name.errors">
                        <div *ngIf="getProfileForm.business_name.errors.required" class="p-error">Business Name is
                          required</div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <span class="p-float-label">
                        <p-inputMask id="phoneNumber" class="w-full" formControlName="business_number"
                          mask="(999) 999-9999" [unmask]="false"></p-inputMask>
                        <label for="phoneNumber">Your Business Phone Number</label>
                      </span>
                    </div>
                    <div class="col-12 ">
                      <span class="p-float-label">
                        <input type="text" id="businessWebsite" formControlName="business_website" class="w-full"
                          pInputText autocomplete="off" />
                        <label for="businessWebsite">Your Business Website</label>
                      </span>
                    </div>
                    <!-- <div class="col-12 ">
                      <span class="p-float-label">
                        <input type="text" id="businessAddress" formControlName="business_address"
                               class="w-full" pInputText autocomplete="off"/>
                        <label for="businessAddress">Your Business Address</label>
                      </span>
                    </div> -->
                    <div class="col-12">
                      <span class="p-float-label">
                        <p-dropdown id="bussiness_industry" formControlName="business_industry"
                          [options]="businessIndustries" [autoDisplayFirst]="false"></p-dropdown>
                        <label for="bussiness_industry">Your Business Industry</label>
                      </span>
                    </div>
                  </div>

                  <h4 class="mt-4">Business Address</h4>
                  <div class="grid" formGroupName="business_address">
                    <div class="col-12">
                      <span class="p-float-label">
                        <input type="text" id="streetAddress" formControlName="street_address" class="w-full" pInputText
                          autocomplete="off" />
                        <label for="streetAddress">Street Address</label>
                      </span>
                    </div>

                    <div class="col-12 grid p-0 m-0">
                      <div class="col-6">
                        <span class="p-float-label">
                          <input id="city" pInputText class="w-full" formControlName="city" />
                          <label for="city">City</label>
                        </span>
                      </div>
                      <div class="col-6">
                        <span class="p-float-label">
                          <p-dropdown (onChange)="onCountrySelection($event)" appendTo="body" [options]="countries"
                            formControlName="country" optionLabel="label" [filter]="true" filterBy="label"
                            [autoDisplayFirst]="false" [virtualScroll]="true" [virtualScrollItemSize]="42">
                            <ng-template pTemplate="selectedItem">
                              <div class="flex" *ngIf="selectedCountry">
                                <img src="assets/images/flags/{{selectedCountry?.code}}.svg" class="country_flag" />
                                <div class="flex-1">{{selectedCountry?.label}}</div>
                              </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                              <div class="flex">
                                <img src="assets/images/flags/{{item?.code}}.svg" class="country_flag" />
                                <div class="flex-1">{{item?.label}}</div>
                              </div>
                            </ng-template>
                          </p-dropdown>
                          <label>Country</label>
                        </span>
                      </div>
                    </div>

                    <div class="col-12 grid p-0 m-0">
                      <div class="col-6">
                        <span class="p-float-label">
                          <input id="zipCode" pInputText class="w-full" formControlName="zip_code" />
                          <label for="zipCode">ZIP/Postal Code</label>
                        </span>
                      </div>
                      <div class="col-6">
                        <span class="p-float-label">
                          <p-dropdown appendTo="body" [options]="provinces" optionLabel="name" formControlName="province" [filter]="true" filterBy="name"
                            [autoDisplayFirst]="false">
                          </p-dropdown>
                          <label>Province</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div id="checkout" class="col-12 " *ngIf="this.stepIndex == 3">
                <form [formGroup]="checkoutForm" [elementsOptions]="elementsOptions" ngxStripeCardGroup>
                  <h4>One Last Step</h4>
                  <div class="mb-2 "><label for="code">Submit your payment information to get started</label></div>
                  <div class="grid">
                    <div class="col-12 mt-3">
                      <span class="p-float-label">
                        <input type="text" id="nameOnCard" formControlName="card_name"
                          [disabled]="!elementsOptions?.clientSecret" class="w-full" pInputText autocomplete="off" />
                        <label for="nameOnCard">Name on Card</label>
                      </span>
                      <div *ngIf="submitted && getCheckoutForm.card_name.errors">
                        <div *ngIf="getCheckoutForm.card_name.errors.required" class="p-error">Name is required</div>
                      </div>
                    </div>

                    <div class="col-12">
                      <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
                        <ngx-stripe-payment [options]="cardOptions" [elementsOptions]="elementsOptions" [clientSecret]="clientSecret">
                        </ngx-stripe-payment>
                      </ng-container>
                    </div>
                  </div>
                </form>
              </div>

              <div id="end" class="col-12 " *ngIf="this.stepIndex == 4">
                <div class="text-center">
                  <h4>Congratulation!</h4>
                  <p>You have completed the registration.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 flex">
          <div class="p-fluid">
            <h5>SUMMARY</h5>
              <p-dropdown [options]="uniqueProducts" *ngIf="uniqueProducts?.length" name="selectedProduct" [(ngModel)]="selectedProduct"  
              (onChange)="getSelectedProduct()"></p-dropdown><br>
                <div class="flex align-items-center">
                    <p-radioButton id="month" name="planDuration" [value]="monthlyPlan" [(ngModel)]="selectedPlan" inputId="monthlySubscription" p-label-active></p-radioButton>
                    <label for="monthlySubscription" class="ml-2 flex justify-content-between w-full"><span>Monthly Subscription</span> <span> ${{monthlyPlan?.value?.price || ''}}</span></label>
                </div> <br>
                <div class="flex align-items-center yeardropdown">
                    <p-radioButton id="year" name="planDuration" [value]="yearlyPlan" [(ngModel)]="selectedPlan" inputId="yearlySubscription"></p-radioButton>
                    <label for="yearlySubscription" class="ml-2 flex justify-content-between align-items-start w-full"><span class="flex flex-column yeartext"><p class="mb-0">Annual Subscription, Billed Yearly </p><label class="ml-2 promo">Save up to 20% </label></span> <span>${{yearlyPlan?.value?.price || ''}}</span></label>
                  </div>
            <div class="flex justify-content-between">
              <h5 class="mt-2">Starting Available Balance</h5>
              <div class="col-3 hidden-xs"></div>
              <div class="col-9 md:col-4">
                <div class="styled-select text-gray-400">
                  <p-dropdown [options]="prices" [(ngModel)]="selectedPrice"
                    (onChange)="onBalanceChange($event)"></p-dropdown>
                </div>
              </div>
            </div>
            <label class="block  font-12 mt-5">
              {{description}}
            </label>
            <div class="mt-2 line" style="height: 3px"></div>
            <div class="justify-content-between " style="display: flex;">
              <h5>Charged Today</h5>
              <p id="price_sel">${{balance}}</p>
            </div>
            <div class="mt-2 line" style="height: 3px"></div>
            <div class="grid">
              <div class="col-12 ">
                <button pButton pRipple type="button" label="Continue" class="p-button-secondary mr-2 mb-2"
                  *ngIf="!elementsOptions?.clientSecret" [disabled]="loading" (click)="next()"
                  style="width: 150px; float: right"></button>
                <button pButton pRipple type="button" class="p-button-secondary mr-2 mb-2"
                  *ngIf="elementsOptions?.clientSecret as clientSecret"
                  [disabled]="(!elementsOptions?.clientSecret) || loading" (click)="pay()"
                  style="width: 150px; float: right" label="Checkout"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_login text-center">
        <p>Do you already have an account?</p>
        <a class="btn btn-primary" [routerLink]="['/login']" href="javascript:void(0)">Sign in</a>
      </div>
    </div>
  </div>
</div>